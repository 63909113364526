/**
 *
 */
export default defineNuxtRouteMiddleware(() => {
    const hotelStore = useHotelStore();
    const upsellingFlowStore = useUpsellingFlowStore();

    if (!hotelStore.product) {
        hotelStore.reset();
        return navigateTo(getFirstFlowRoute(upsellingFlowStore.productConfig?.steps));
    }
});
